import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="checkbox-list"
export default class extends Controller {
  static targets = [ "label" ]
  static values = { label: String }

  connect() {
    this.checkedCheckboxCount = 0
    this.updateLabel
  }

  updateLabelText() {
    this.checkedCheckboxCount = this.selectedCheckboxes.length
    // Remove "All" from label text
    let labelText = this.labelValue.replace("All", "").trim()
    
    if (this.checkedCheckboxCount > 0) {
      this.labelTarget.textContent = `(${this.checkedCheckboxCount}) ${labelText}`
    } else {
      this.labelTarget.textContent = `All ${labelText}`
    }
  }

  get selectedCheckboxes() {
    return this.checkboxes.filter(checkbox => checkbox.checked)
  }

  get checkboxes() {
    return new Array(...this.element.querySelectorAll("input[type=checkbox]"))
  }
}
